.dashboardPage {
  /* height: 100vh; */
  overflow-y: auto;
  background: linear-gradient(
    90deg,
    rgba(0, 32, 71, 1) 0%,
    rgba(0, 92, 209, 1) 61%
  ) !important;
}

.sidebar ul {
  margin: 20px 0;
}

.sidebar hr {
  display: none !important;
}

.sidebar ul a button svg path {
  fill: white;
}

.sidebar ul a button svg {
  width: 22px;
}

.sidebar ul a.Mui-selected {
  background: #ffffffd6 !important;
  color: #011f44 !important;
}

.sidebar ul a:not(.Mui-selected):hover {
  background: #005cd1 !important;
  color: #fff !important;
}

.sidebar ul a {
  border-radius: 4px;
  padding: 8px 12px;
}

.sidebar ul a p {
  line-height: 10px;
  font-size: 14px;
}

.sidebar ul a.Mui-selected svg path {
  fill: #011f44 !important;
}

.sidebar ul > span {
  color: #ffffff80;
  text-transform: uppercase;
  font-size: 9px;
}

.sidebar-prf .popup {
  width: 100%;
  z-index: 1400;
  border-radius: 12px;
  left: 50% !important;
  transform: translate(-50%, -61px) !important;
  bottom: 200px;
}

.sidebar-prf .popup nav p {
  color: #10294e;
  text-wrap: nowrap;
}

.sidebar-prf .popup .MuiListItemIcon-root {
  min-width: 31px;
}

.sidebar-prf .popup svg {
  color: #10294e;
}

.sidebar-prf {
  position: absolute;
  bottom: 0;
  transform: translatex(-50%);
  z-index: 1400;
}

.sidebar-prf .MuiChip-root {
  backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  background-color: rgba(255, 255, 255, 0.1);
  box-shadow: 0px 8px 30px 0px rgba(0, 0, 0, 0.1);
  width: 100%;
  border-radius: 12px 12px 0 0;
  padding: 10px;
  min-height: 60px;
  justify-content: flex-start;
}

nav .MuiDrawer-paper {
  background: transparent !important;
}

.dashboardPage header {
  width: 230px;
  left: 0;
  padding: 0;
}

.dashboardPage main {
  margin: 20px 0 0;
  background: #f5f5f5;
  min-height: 100vh;
  padding: 0;
  border-radius: 24px 0 0 0;
  box-shadow: -3px 0px 10px #00000045;
}

.admin-header.active {
  padding-left: 20px;
}

.admin-header.active span {
  display: none;
}

.active .sidebar {
  width: 80px;
}

.active .sidebar ul a,
.active .sidebar ul a.Mui-selected {
  padding: 0;
  justify-content: center;
  background: transparent !important;
}

.active .sidebar ul a button div {
  border-radius: 99px;
}

.borderBottom {
  border-bottom: 1px solid #c6d3de;
}

.borderTop {
  border-top: 1px solid #c6d3de;
}

.topBarBtn {
  max-height: 40px;
}

body .popup .MuiList-root {
  max-width: 100% !important;
  min-width: 100% !important;
}

.ps__rail-x,
.ps__rail-y {
  display: none !important;
}

.project_header_wrap .popup {
  right: 22px !important;
  transform: none !important;
  left: auto !important;
  top: 75px !important;
  max-width: 250px;
  width: 100%;
}

/* table tbody tr:hover, */
.analytic-tb table tr:hover,
.my-package-tb tr:not(:first-child):hover {
  background: #d7e3eb !important;
}

.select input,
.search input {
  padding: 12px 10px !important;
}

.search svg path,
.select svg path,
.newStyleField svg path {
  color: #10294e;
}

/* search-field space*/

body .spaceField input[type="text"] {
  padding: 13px 14px 13px 0 !important;
}

.Projectheader {
  display: flex;
  justify-content: space-between;
  max-width: 1488px;
  margin: 0 auto;
  width: 100%;
}

.project_header_wrap {
  text-align: center;
}

.project_target_link:hover svg {
  transform: translateX(10px);
}

.project_target_link:hover {
  background: #ccd9e2 !important;
}

.bgBlueOverlay .MuiModal-backdrop {
  background-color: #67a2e19e;
  backdrop-filter: blur(10px);
}

.quick-action-links:hover {
  background: #f8fafb !important;
  box-shadow: 0 7px 10px #00000014;
}

.quick-action-links:hover .arrow {
  transform: translateX(10px);
}

.direct-card a {
  color: #697586;
  text-decoration: none;
  margin-top: 0;
  display: inline-block;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
}

.direct-card a:hover {
  text-decoration: underline;
}

.direct-card:hover {
  box-shadow: 0 10px 10px #0000001c;
}

.direct-card .MuiCardContent-root {
  padding-top: 0;
}

.direct-card .MuiCardHeader-content span:first-child {
  font-size: 18px;
  font-weight: bold;
}

.direct-card .MuiCardHeader-content span:last-child {
  /* font-size: 14px; */
  color: #000;
}

.direct-card .MuiCardHeader-avatar {
  width: 80px;
  height: 60px;
  background: #eee !important;
  padding: 5px;
  border-radius: 8px;
  margin-right: 10px !important;
}

.direct-card .MuiCardHeader-avatar img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  background: transparent !important;
}

.monthSelect input[type="text"] {
  padding: 10px 0 10px 15px !important;
  height: 40px;
  min-width: 100px;
}

.addProjectModal .MuiDialog-paper {
  max-width: 80%;
  width: 80%;
  margin: 0;
  padding: 0;
  max-height: 100%;
  overflow: unset;
  border-radius: 12px;
}

.addProjectModal .MuiStepper-root {
  padding: 27px 70px 20px;
  background: #eff2f6;
  border-radius: 12px;
}

.modalContent form .MuiPaper-root:first-child .MuiGrid-item:first-child {
  overflow: visible;
}

.modalContent form .MuiPaper-root:first-child .MuiGrid-item:first-child {
  max-height: calc(100vh - 260px);
}

.modalContent form .MuiPaper-root:first-child {
  margin: 0;
  border-radius: 0;
}

.modalContent
  form
  .MuiPaper-root:first-child
  .MuiGrid-item:first-child::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.modalContent
  form
  .MuiPaper-root:first-child
  .MuiGrid-item:first-child::-webkit-scrollbar-track {
  background: #eff2f6;
}

/* Handle */
.modalContent
  form
  .MuiPaper-root:first-child
  .MuiGrid-item:first-child::-webkit-scrollbar-thumb {
  background: #ccd9e2;
  border-radius: 20px;
}

/* Handle on hover */
.modalContent
  form
  .MuiPaper-root:first-child
  .MuiGrid-item:first-child::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.direct-card .MuiAvatar-root {
  width: 50px;
  height: 50px;
}

.direct-card button svg {
  margin-left: -10px;
}

/* Handle the case where the table only has one cell */
body table tbody td:only-child {
  border-radius: 12px !important; /* Adjust radius value as needed */
}

body table tbody tr th:first-child {
  border-radius: 12px 0 0 12px;
  font-weight: bold;
}

body table tbody th:last-child {
  border-radius: 0 12px 12px 0 !important;
}

/* Handle the case where the table only has one cell */
body table tbody th:only-child {
  border-radius: 12px !important; /* Adjust radius value as needed */
}

.single-table-row {
  border-radius: 12px 12px 12px 12px !important;
}

table tr td.center,
table tr th.center {
  text-align: center !important;
}

table tr td.right,
table tr th.right {
  text-align: right !important;
}

.localContent-tb table th p {
  font-size: 12px;
}

.my-package-tb tr:first-child {
  background: transparent;
  margin: 0 !important;
}

/*tabs*/

.MuiToggleButtonGroup-root .MuiToggleButton-root {
  border: 1px solid rgb(208, 215, 222) !important;
  color: #000;
  padding: 10px 13px;
  font-size: 14px;
  line-height: 16px;
}

.MuiToggleButtonGroup-root .MuiToggleButton-root:hover {
  background: #e9f0f5 !important;
}

.listItems ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.listItems {
  padding: 24px 20px 20px;
}

.listItems ul li {
  margin-bottom: 18px;
  padding-left: 37px;
  position: relative;
  font-size: 14px;
  line-height: 24px;
  color: red;
  font-weight: 500;
}

.popuptabs .MuiTab-root {
  margin-right: 10px;
  border: 1px solid transparent !important;
  border-radius: 5px !important;
  color: #000;
  padding: 10px 13px;
  font-size: 14px;
  line-height: 16px;
  min-height: auto;
}

.popuptabs .MuiTabs-indicator {
  display: none;
}

.popuptabs .MuiTabs-flexContainer {
  border: none;
}

.popuptabs .MuiTab-root:not(.Mui-selected):hover {
  background: #e9f0f5 !important;
}

.employpop table {
  margin-top: 10px;
}

.topTable > div {
  background: #e9f0f5;
  margin-top: 5px !important;
  border-radius: 12px !important;
  transition: 0.3s all;
  padding: 10px 15px;
}

.topTable {
  max-width: 400px;
}

.topTable > div:hover {
  background: #d7e3eb !important;
}

.topTable .avatar {
  max-width: max-content;
}

body .MuiMenu-list {
  padding: 10px;
}

body .MuiDateCalendar-root {
  padding: 10px 0;
}

body .MuiPickersMonth-monthButton.Mui-selected {
  background: #000 !important;
}

.projectIcon path {
  fill: transparent !important;
}

body tr td.fullBorderRad:first-child {
  border-radius: 12px !important;
}

body .projectTextField input[type="text"],
body .transpField input[type="text"] {
  padding: 10px 15px 10px 15px !important;
  background: transparent;
}

body .projectTextField .MuiOutlinedInput-root,
body .transpField .MuiOutlinedInput-root {
  background: transparent;
}

.statusField fieldset {
  top: 0;
}

.statusField {
  margin: 0;
}

.MuiToggleButtonGroup-root,
.popuptabs .MuiTabs-flexContainer {
  /* border: 1px solid rgb(208, 215, 222); */
  width: max-content;
  border-radius: 5px;
}

.MuiToggleButton-root,
.popuptabs .MuiTabs-flexContainer {
  border: 1px solid rgb(208, 215, 222) !important;
  width: max-content;
  border-radius: 5px;
}

.MuiToggleButtonGroup-root .MuiToggleButton-root:not(:last-child) {
  border-right: 1px solid rgb(208, 215, 222);
}

.MuiToggleButtonGroup-root .MuiToggleButton-root:hover {
  background: #eaedf0 !important;
}

.MuiToggleButtonGroup-root .MuiToggleButton-root.Mui-selected,
.popuptabs .Mui-selected {
  background: #f2f2f2 !important;
  color: #000;
  font-weight: 500;
}

.MuiToggleButtonGroup-root .MuiToggleButton-root:first-child,
.popuptabs .MuiTab-root:first-child {
  border-radius: 5px 0 0 5px !important;
}

.MuiToggleButtonGroup-root .MuiToggleButton-root:last-child,
.popuptabs .MuiTab-root:last-child {
  border-radius: 0 5px 5px 0 !important;
}

.borderBottom .MuiTypography-h1 {
  font-size: 24px !important;
}

.borderBottom .MuiTypography-h1 {
  font-size: 24px !important;
}

.newStyleField > div > .MuiOutlinedInput-root {
  height: 40px !important;
}

.search fieldset,
.select fieldset,
.newStyleField fieldset {
  border-radius: 6px;
  padding-right: 6px;
  border: 1px solid rgb(208, 215, 222) !important;
  padding-left: 10px;
}

body .select input {
  padding: 8px 6px 8px 0 !important;
  font-size: 14px;
  color: #000 !important;
  background: white;
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  height: 10px !important;
}
body .search input {
  padding: 8px 6px 8px 0 !important;
  font-size: 14px;
  color: #000 !important;
  background: rgb(246, 248, 250);
  font-weight: 400;
  font-family: "Roboto", sans-serif;
  height: 10px !important;
}

body .select input::placeholder,
body .search input::placeholder {
  color: #000 !important;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
}

body .search .MuiOutlinedInput-root {
  height: 40px;
  background: rgb(246, 248, 250);
}

body .select .MuiOutlinedInput-root {
  height: 40px;
  background: white;
}

body .MuiAutocomplete-root {
  background: white;
}

.select,
.search {
  min-width: 300px;
}

.tableInput {
  min-width: 120px !important;
  max-width: 300px !important;
  margin: 0px;
}

body .tableInput input[type="text"] {
  padding: 5px 7px 5px 7px !important;
  background: transparent;
}

.arrowLink:hover svg {
  transform: translatex(3px);
}
.arrowLink:hover a {
  background: #c6d3de;
}

td.fullBorderRad {
  height: 49px;
}

td.fullBorderRad p {
  font-weight: 400 !important;
  font-size: 14px;
}

.progress {
  width: 100%;
  background: #e8eff4;
  display: flex;
  border-radius: 30px;
  height: 16px;
}

.logos-group .MuiAvatarGroup-root {
  justify-content: left;
}

.sidebarmain .MuiDrawer-paper {
  padding-top: 95px;
  transition: 0.3s all;
}

/* dashboard cards new styles */
.assigned .tasks-status-color {
  background: #dff4ff;
  border-color: #0870e5;
}

.inreview .tasks-status-color {
  background: #fff3e3;
  border-color: #ff5a00;
}

.overdue .tasks-status-color {
  background: #fff4f4;
  border-color: #f30323;
}

.approved .tasks-status-color {
  background: #dcfae3;
  border-color: #00aa30;
}

.notassigned .tasks-status-color {
  background: #fff8d7;
  border-color: #ff9900;
}

.notapplicable .tasks-status-color {
  background: #fff3e3;
  border-color: #a9a9a9;
}

/* new custom progress bar */
.progress {
  width: 100%;
  background: #eaebed;
  display: flex;
  border-radius: 30px;
  height: 16px;
}

.progress > div:first-child {
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
}

.progress > div:last-child {
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}

.progress > div:not(:last-child) {
  margin-right: 2px;
}

.progress-bar-labels .prog-bar-label {
  color: #000 !important;
  font-weight: 500;
  font-size: 11px;
  font-family: "Roboto", sans-serif;
}

.progress-bar-labels .prog-bar-percentage {
  color: rgb(101, 109, 118);
  font-weight: 500;
  font-size: 11px;
}

.dashb-cards .MuiCard-root:hover {
  box-shadow: 0 1px 10px #00000030;
}

.dashb-cards .MuiCard-root {
  transition: 0.3s all;
  height: 100%;
}

.dashb-cards .MuiCard-root > div {
  height: 100%;
}

.profileChip .MuiAvatar-root {
  background: #c9c3c3;
  width: 37px;
  height: 37px;
  margin: 0 !important;
}

.profileChip .MuiAvatar-root svg {
  stroke: inherit !important;
}

.quill-container {
  padding: 6px 0px;
  font-size:13px;
}
.quill-container p {
  margin: 0px;
  font-size: 13px;
  font-weight: 400;
  color: #333;
  line-height: 1.5;
  color: rgb(51, 51, 51);
  font-family: Helvetica, Arial, sans-serif !important;
}

.quill-container ul {
  padding-left: 1.5em;
}
.quill-container ol > li,
.quill-container ul > li {
  list-style-type: none;
}
.quill-container ul > li::before {
  content: '\2022';
}
.quill-container ul[data-checked=true],
.quill-container ul[data-checked=false] {
  pointer-events: none;
}
.quill-container ul[data-checked=true] > li *,
.quill-container ul[data-checked=false] > li * {
  pointer-events: all;
}

.quill-container li:not(.ql-direction-rtl)::before {
  margin-left: -1.5em;
  margin-right: 0.3em;
  text-align: right;
}
.quill-container ul li:not(.ql-direction-rtl) {
  padding-left: 1.5em;
}

.quill-container li::before {
  display: inline-block;
  white-space: nowrap;
  width: 1.2em;
}

.quill-container {
  border: none !important;
  padding: 6px 0px;
}

.ql-editor {
  border: none !important;
  outline: none !important;
  padding: 0px 0px !important;
  min-height: 150px;
}

.ql-snow.ql-toolbar button {
  padding: 0px 0px !important;
  width: 18px !important;
  height: 18px !important;
}

.quill-container ol {
  list-style: none;
}

.quill-container ol li {
  counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  counter-increment: list-0;
  position: relative;
}

.quill-container ol li:before {
  content: counter(list-0, decimal) '. ';
  position: absolute;
  left: -5px; /* Adjust as needed */
}

.ql-snow{
  .ql-picker{
      &.ql-size{
          .ql-picker-label,
          .ql-picker-item{
              &::before{
                  content: attr(data-value) !important;
              }
          }
      }
  }
  }